import React, { useState } from "react"
import { Link } from "gatsby"

import {
  Card,
  CardHeader,
  CardBody,
  Collapse,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
} from "reactstrap"

import Layout from "../components/layout"
import { Parallax } from "react-parallax"
import BgImage from "../../static/img/bg3cropped.jpg"
import CharlieWilson from "../../static/img/ceo-charlie-wilson.jpg"
import SssImage from "../../static/img/sss-1.png"
import CetsImage from "../../static/img/cets.png"
import CECOInvestment from "../../static/img/CECO-investments.png"
import CarbonOffsetInvestment from "../../static/img/carbon-offset-investments.png"
import CarbonOffsetsByRegion from "../../static/img/carbon-offsets-by-region.png"
import SlideGlobalCarbonProblem from "../../static/img/slide-global-carbon-problem.jpg"
import SlideRepurposingTeams from "../../static/img/slide-repurposing-teams.jpg"
import SlideSuccessful from "../../static/img/slide-successful.jpg"
import SlideProcess from "../../static/img/slide-process.jpg"
import SlideSolution from "../../static/img/slide-solution.jpg"
import SlideCertificate from "../../static/img/slide-certificate.jpg"
import SlideCaseStudy1 from "../../static/img/slide-case-study-1.jpg"
import SlideCaseStudy2 from "../../static/img/slide-case-study-2.jpg"
import SlideCaseStudy3 from "../../static/img/slide-case-study-3.jpg"

const items = [
  {
    src: SlideCaseStudy1,
  },
  {
    src: SlideCaseStudy3,
  },
]

const CaseStudies = (props) => {
  const slides = items.map((item) => {
    return (
      <p className="mt-2 mb-4 pb-4 border-bottom">
        <img src={item.src} className="img-fluid" />
      </p>
    )
  })

  return <div>{slides}</div>
}

class SustainabilitySolutionsPage extends React.Component {
  constructor(props) {
    super(props)
    this.toggle = this.toggle.bind(this)
    this.state = {
      collapse: 0,
      cards: [
        {
          index: 1,
          header: "Global Carbon Problem",
          body: "Global Carbon Problem",
          image: SlideGlobalCarbonProblem,
        },
        {
          index: 2,
          header: "Circular Economy Carbon Offset Solution",
          image: SlideSolution,
        },
        {
          index: 3,
          header: "Carbon Offset Certificates",
          image: SlideCertificate,
        },
        {
          index: 4,
          header: "Case Study",
          body: "Case Study",
          carousel: true,
        },
        {
          index: 5,
          header: "Repurposing Teams",
          body: "Repurposing Teams",
          image: SlideRepurposingTeams,
        },
        {
          index: 6,
          header: "Successful Circular Economy Carbon Offsets",
          image: SlideSuccessful,
        },
      ],
    }
  }
  toggle(e) {
    let event = e.target.dataset.event
    this.setState({
      collapse: this.state.collapse === Number(event) ? 0 : Number(event),
    })
  }
  render() {
    const { cards, collapse } = this.state
    return (
      <Layout>
        <Parallax bgImage={BgImage} className="text-center parallax-secondary">
          <div className="container-fluid">
            <div className="row">
              <div className="col"></div>
              <div className="col-md-7">
                <h1 className="text-white wow fadeInDown">
                  SUSTAINABILITY SOLUTIONS
                </h1>
              </div>
              <div className="col"></div>
            </div>
          </div>
        </Parallax>
        <div className="container-fluid pb-4">
          <div className="container mb-4">
            <div className="row">
              <div className="col pt-4 wow fadeInLeft">
                <h2 className="text-primary mt-4 text-center">
                  Carbon Offsets To Reduce Your Footprint To Net Zero
                </h2>
              </div>
              <div className="row wow fadeInRight">
                <div className="col"></div>
                <div className="col-md-8">
                  <p className="mt-4 mb-4 text-center">
                    The circular economy carbon offsets reduce the amount of
                    resources used, waste created and procurement leakage. These
                    programs deliver sustainability teams who repurpose
                    commercial assets and reduce environmental impact.
                  </p>
                </div>
                <div className="col"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid pt-4 pb-4 border-bottom">
          <div className="container mb-4">
            <div className="row">
              <div className="col pt-4">
                <h2 className="text-primary mt-4 mb-4 text-center wow fadeInLeft">
                  Successful Circular Economy Carbon Offsets
                </h2>
                <p className="mt-4 wow fadeInRight">
                  <img src={CarbonOffsetsByRegion} className="img-fluid" />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid pb-4">
          <div className="container mb-4">
            <div className="row">
              <div className="col pt-4">
                <h2 className="text-primary mt-4 text-center">
                  CONTRIBUTION DELIVERABLES
                </h2>
                <p className="mt-4 text-center">
                  Your contribution creates commercial repurposing projects that
                  leads to carbon offsets. Your company can apply to carbon
                  neutral goals. This also leads to jobs in education,
                  scientific study, media production, and sustainability
                  professionals, who deliver your solution.
                </p>
                <div className="row mt-4">
                  <div className="col-12 col-md-4 wow fadeInLeft">
                    <p className="text-center">
                      <strong className="text-dark h3">
                        The Sustainability Search
                      </strong>
                      <br />
                      tool allows corporations to search other companies in
                      their industry for surplus inventory that would satisfy
                      their own procurement needs. Before you purchase your next
                      machine, equipment or inventory, take a minute to see if
                      that item is available in our system, already
                      manufactured, at a discount.
                    </p>
                  </div>
                  <div className="col-12 col-md-4 wow fadeInUp">
                    <p className="text-center">
                      <strong className="text-dark h3">
                        Carbon Offset Validation
                      </strong>
                      <br />
                      through a team of renowned international sustainability,
                      corporate and academic experts who calculate and certify
                      carbon offsets generated by corporations through their
                      participation in the circular economy.
                    </p>
                  </div>
                  <div className="col-12 col-md-4 wow fadeInRight">
                    <p className="text-center">
                      <strong className="text-dark h3">
                        Global Repurposing Team
                      </strong>
                      <br />
                      Developing, through a team of renowned international
                      sustainability, corporate and academic experts, new ways
                      to more precisely reduce CO2 emission. We have worked in
                      over 100 countries providing solutions and training on new
                      repurposing professionals.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid pb-4">
          <div className="container mb-4">
            <div className="row wow fadeInLeft">
              <div className="col-md-12">
                <p className="text-center mt-4">
                  <img
                    src={CECOInvestment}
                    className="img-fluid"
                    alt="CECO Investment"
                  />
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col pt-4">
                <h2 className="text-primary mt-4 text-center wow fadeInLeft">
                  Do your company have assets to sell?
                </h2>
              </div>
            </div>
            <div className="row wow fadeInRight">
              <div className="col"></div>
              <div className="col-md-8">
                <p className="mt-4 mb-4 text-center">
                  Salvex works with procurement departments, inventory managers,
                  warehouse managers, logistics managers, accounting, and
                  sustainability professionals to identify internal solutions
                  and recommend immediate improvements and environmental policy
                  changes. Once the improvements are in place, we provide the
                  case studies and quantify the results which can be projected
                  internally and externally.
                </p>
              </div>
              <div className="col"></div>
            </div>
            <div className="row wow fadeInLeft">
              <div className="col-md-12">
                <p className="text-center mt-4">
                  <a
                    href="https://www.salvex.com/admin/_members/views/_create_listing.cfm"
                    className="btn btn-primary btn-lg rounded-pill pl-4 pr-4"
                  >
                    Post a listing
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid pb-4">
          <div className="container mb-4">
            <div className="row">
              <div className="col pt-4">
                {cards.map((item) => {
                  return (
                    <Card style={{ marginBottom: "1rem" }} key={item.index}>
                      <CardHeader className="bg-primary text-white">
                        <h4
                          className="m-0"
                          onClick={this.toggle}
                          data-event={item.index}
                          style={{ cursor: "pointer" }}
                        >
                          {item.header}
                        </h4>
                      </CardHeader>
                      <Collapse isOpen={collapse == item.index}>
                        <CardBody>
                          {item.image && (
                            <img src={item.image} className="img-fluid" />
                          )}
                          {item.carousel && <CaseStudies />}
                        </CardBody>
                      </Collapse>
                    </Card>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default SustainabilitySolutionsPage
